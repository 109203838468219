import * as Realm from "realm-web";

import { store } from "../../store";
import { BSON } from "realm-web";
import { ObjectId } from "bson";

export default class Mongo {
  static app;
  static user;
  static client;
  static cluster;
  static tokensDb;

  static clusters = {
    free: "free",
    dedicated: "dedicated",
  };
  static dbs = {
    test: "general-test",
    main: "main",
    chartTest: "chart-test",
  };
  static serviceAppIds = {
    free: "free-tier-service-app-zvvcf",
    dedicated: "dedicated-service-app-csaat",
  };

  static objectId(id) {
    return BSON.ObjectID(id);
  }

  static async connectToFreeTier() {
    Mongo.app = new Realm.App({ id: Mongo.serviceAppIds.free });
    Mongo.user = await Mongo.app.logIn(Realm.Credentials.anonymous());
    Mongo.client = Mongo.app.currentUser.mongoClient("mongodb-atlas");
  }
  static async connectToDedicated() {
    const {
      auth: { mongoJwt },
    } = store.getState();

    // Mongo.app = new Realm.App({ id: Mongo.serviceAppIds.dedicated });
    // Mongo.user = await Mongo.app.logIn(Realm.Credentials.anonymous());
    // Mongo.client = Mongo.app.currentUser.mongoClient("mongodb-atlas");
    Mongo.app = new Realm.App({ id: Mongo.serviceAppIds.dedicated });
    const credentials = Realm.Credentials.jwt(mongoJwt);
    Mongo.user = await Mongo.app.logIn(credentials);
    Mongo.client = Mongo.app.currentUser.mongoClient("mongodb-atlas");
  }
  static async connect() {
    if (Mongo.cluster === Mongo.clusters.free) {
      await Mongo.connectToFreeTier();
    } else if (Mongo.cluster === Mongo.clusters.dedicated) {
      await Mongo.connectToDedicated();
    }
  }
  static async close() {
    // todo: close connection
    // Mongo.client && Realm.close();
    Mongo.user = null;
    Mongo.app = null;
    Mongo.client = null;
  }
}
