import { useState } from "react";
import { React } from "react";
import { Select, Text } from "@mantine/core";

export default function AmountUsdPicker({ value, onChange }) {
  const [options, setOptions] = useState([
    "0",
    "10",
    "50",
    "100",
    "250",
    "500",
    "750",
    "1000",
    "1500",
    "2000",
    "2500",
    "3000",
    "4000",
    "5000",
    "7500",
    "10000",
  ]);

  return (
    <Select
      label={<Text fw={700}>Amount ($)</Text>}
      maxDropdownHeight={400}
      placeholder="Amount ($)"
      searchable={true}
      creatable={true}
      getCreateLabel={(query) => `+ Create ${query}`}
      onCreate={(query) => {
        const item = { value: query, label: query };
        setOptions((current) => [...current, item]);
        return item;
      }}
      data={options}
      value={value}
      onChange={onChange}
    />
  );
}
