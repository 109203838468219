import { IconPlus, IconX } from "@tabler/icons-react";
import { Button, CopyButton, Grid, Input, Switch } from "@mantine/core";

function SellOnPrice({
  strategy,
  currentPrice,
  updateStrategy,
  removeStrategy,
}) {
  return (
    <Grid>
      <Grid.Col lg={3} style={{ display: "flex", alignItems: "center" }}>
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          onClick={() => removeStrategy(strategy)}
        >
          Sell On ($)
        </Button>
      </Grid.Col>
      <Grid.Col lg={2}>
        <Input
          label="Down $"
          placeholder="Down $"
          value={strategy.params.down}
          onChange={(e) =>
            updateStrategy({
              ...strategy,
              params: { ...strategy.params, down: e.target.value },
            })
          }
        />
      </Grid.Col>
      <Grid.Col lg={2}>
        <Input
          label="Up $"
          placeholder="Up $"
          value={strategy.params.up}
          onChange={(e) =>
            updateStrategy({
              ...strategy,
              params: { ...strategy.params, up: e.target.value },
            })
          }
        />
      </Grid.Col>
      <Grid.Col lg={2}>
        <CopyButton value={currentPrice}>
          {({ copied, copy }) => (
            <Button variant="white" color="dark" onClick={copy}>
              {currentPrice}$
            </Button>
          )}
        </CopyButton>
      </Grid.Col>
      <Grid.Col
        lg={3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Switch
          label={<b>AND</b>}
          checked={strategy.params.and}
          onClick={(e) =>
            updateStrategy({
              ...strategy,
              params: { ...strategy.params, and: e.target.checked },
            })
          }
        />
      </Grid.Col>
    </Grid>
  );
}

SellOnPrice.type = "sell";
SellOnPrice.color = "pink";
SellOnPrice.model = {
  name: "sell_on_price",
  title: "Sell on price",
  params: { up: "", down: "" },
};
SellOnPrice.paramsTitle = (strategy) => {
  if (strategy.params.down !== "" && strategy.params.up !== "") {
    return `${strategy.params.down} - ${strategy.params.up}`;
  } else if (strategy.params.down !== "") {
    return `<= ${strategy.params.down}$`;
  } else if (strategy.params.up !== "") {
    return `>= ${strategy.params.up}$`;
  } else {
    return `-`;
  }
};
SellOnPrice.Button = function (props) {
  return (
    <Button variant="default" color="pink" leftIcon={<IconPlus />} {...props}>
      Sell On ($)
    </Button>
  );
};

export default SellOnPrice;
