import { Fragment } from "react";
import {
  IconPlus,
  IconCirclePlus,
  IconCircleMinus,
  IconReload,
} from "@tabler/icons-react";
import {
  Avatar,
  Box,
  Button,
  Group,
  Text,
  UnstyledButton,
} from "@mantine/core";

import { Strategies } from "../config";

const StrategyButton = ({ type, openModal, isFake, strategy, disable }) => {
  return (
    <UnstyledButton key={strategy.id} onClick={openModal} disabled={disable}>
      <Group>
        <Avatar
          size={40}
          color={
            isFake
              ? "gray"
              : type === "buy"
              ? "teal"
              : type === "sell"
              ? "red"
              : "violet"
          }
        >
          {type === "buy" ? (
            <IconCirclePlus />
          ) : type === "sell" ? (
            <IconCircleMinus />
          ) : (
            <IconReload />
          )}
        </Avatar>
        <div>
          <Text fw={700} size="sm">
            {strategy.title || strategy.name}
          </Text>
          <Text size="sm">
            {Strategies[strategy.name]?.paramsTitle(strategy) || strategy.name}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  );
};

export function StrategyPickerBoard({
  symbol,
  openModal,
  isFake,
  buyStrategies = [],
  sellStrategies = [],
  repeatStrategies = [],
  disableButtons,
}) {
  return (
    <Box
      shadow="xs"
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "0.5rem",
      }}
    >
      {buyStrategies?.length ||
      sellStrategies?.length ||
      repeatStrategies.length ? (
        <Group align="center" justify="center">
          {buyStrategies.map((strategy) => {
            return (
              <Fragment>
                <StrategyButton
                  key={strategy.id}
                  type="buy"
                  openModal={openModal}
                  isFake={isFake}
                  strategy={strategy}
                  disable={disableButtons}
                />
                {strategy?.params?.and && <b>+</b>}
              </Fragment>
            );
          })}
          {sellStrategies.map((strategy) => {
            return (
              <Fragment>
                <StrategyButton
                  key={strategy.id}
                  type="sell"
                  openModal={openModal}
                  isFake={isFake}
                  strategy={strategy}
                  disable={disableButtons}
                />
                {strategy?.params?.and && <b>+</b>}
              </Fragment>
            );
          })}
          {repeatStrategies.map((strategy) => {
            return (
              <Fragment>
                <StrategyButton
                  key={strategy.id}
                  type="repeat"
                  openModal={openModal}
                  isFake={isFake}
                  strategy={strategy}
                  disable={disableButtons}
                />
              </Fragment>
            );
          })}
        </Group>
      ) : (
        <Group align="center" justify="center">
          <Button
            variant="light"
            color={isFake ? "gray" : "teal"}
            onClick={openModal}
            leftIcon={<IconPlus />}
          >
            Strategy
          </Button>
        </Group>
      )}
    </Box>
  );
}
