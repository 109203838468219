import { IconPlus, IconX } from "@tabler/icons-react";
import { Text, Button, Grid, SegmentedControl, Switch } from "@mantine/core";

function HABuy({ strategy, updateStrategy, removeStrategy }) {
  return (
    <Grid>
      <Grid.Col lg={3}>
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          // color="violet"
          onClick={() => removeStrategy(strategy)}
        >
          H.A. Buy
        </Button>
      </Grid.Col>
      <Grid.Col lg={9}>
        <Grid>
          <Grid.Col lg={8}>
            <SegmentedControl
              value={strategy.params.interval}
              onChange={(value) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, interval: value },
                })
              }
              data={[
                { value: "1m", label: "1m" },
                { value: "3m", label: "3m" },
                { value: "5m", label: "5m" },
                { value: "15m", label: "15m" },
                { value: "1h", label: "1h" },
                { value: "4h", label: "4h" },
                { value: "1d", label: "1d" },
                { value: "1w", label: "1w" },
              ]}
            />
          </Grid.Col>
          <Grid.Col
            lg={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              label={<b>AND</b>}
              checked={strategy.params.and}
              onClick={(e) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, and: e.target.checked },
                })
              }
            />
          </Grid.Col>
          <Grid.Col lg={12}>
            <SegmentedControl
              value={strategy.params.threshold}
              onChange={(value) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, threshold: value },
                })
              }
              data={[
                { value: "0.001", label: "no" },
                { value: "0.1", label: "0.1" },
                { value: "0.3", label: "0.3" },
                { value: "0.5", label: "0.5" },
                { value: "1", label: "1" },
                { value: "3", label: "3" },
                { value: "5", label: "5" },
                { value: "10", label: "10" },
              ]}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

HABuy.type = "buy";
HABuy.color = "violet";
HABuy.model = {
  name: "ha_buy",
  title: "H.A. Buy",
  params: {
    interval: "1m",
    threshold: "1",
  },
};
HABuy.paramsTitle = (strategy) => {
  return `${strategy.params.interval}, ${strategy.params.threshold}%`;
};

HABuy.Button = function (props) {
  return (
    <Button variant="default" color="violet" leftIcon={<IconPlus />} {...props}>
      H.A.Buy
    </Button>
  );
};

export default HABuy;
