import { useState, useMemo } from "react";
import { Button, Grid, Center, Group, SegmentedControl } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { IconPlus } from "@tabler/icons-react";

import NewOrder from "./NewOrder";
import Order from "./Order";

import { useEffect } from "react";
import { watchOrders } from "../common/data/orders/redux";
import { WATCH_PRICES } from "../common/data/currentPrice/redux";

export default function Orders() {
  const dispatch = useDispatch();

  const [visibilityGroup, setVisibilityGroup] = useState("main");

  useEffect(() => {
    dispatch({ type: WATCH_PRICES });
  }, []);

  const [profits, setProfits] = useState({});
  const [showNewOrderForm, setShowNewOrderForm] = useState(true);

  // const { sendMessage, lastMessage, readyState } = useWebSocket(
  //   `wss://stream.binance.com:9443/ws/btcusdt@kline_1s/eduusdt@kline_1s`
  // );
  // const data = lastMessage && JSON.parse(lastMessage.data);
  // const currentPrice = parseFloat(data?.p) || 0;

  const currentPrice = 12312313;

  useEffect(() => {
    dispatch(watchOrders());
  }, []);

  useEffect(
    function updateDocumentTitle() {
      document.title = Object.keys(profits).reduce(
        (str, id) =>
          `${str}${!!str ? " | " : ""}${profits[id].symbol.replace(
            "USDT",
            ""
          )} ${profits[id].profit || 0}`,
        ""
      );
    },
    [profits]
  );

  let orders = useSelector((state) => state.orders);

  let sortedOrders = useMemo(() => {
    if (!orders?.real?.length) return [];

    let sorted = [...orders?.real].sort(
      (a, b) => new Date(b.created) - new Date(a.created)
    );

    return sorted.filter((order) => {
      if (visibilityGroup === "main") return !order.isHiddenGroup;
      else if (visibilityGroup === "hidden") return order.isHiddenGroup;
      else if (visibilityGroup === "real") return !order.isFake;
      else if (visibilityGroup === "fake") return order.isFake;
    });
  }, [orders, visibilityGroup]);

  return (
    <Grid>
      <Grid.Col>
        <Center>
          <Group>
            <SegmentedControl
              value={visibilityGroup}
              onChange={(value) => setVisibilityGroup(value)}
              transitionDuration={0}
              data={[
                { label: "Main", value: "main" },
                { label: "Hidden", value: "hidden" },
                { label: "Real", value: "real" },
                { label: "Fake", value: "fake" },
              ]}
            />
          </Group>
        </Center>
      </Grid.Col>
      {showNewOrderForm ? (
        <Grid.Col md={6} sm={12}>
          <NewOrder hideForm={() => setShowNewOrderForm(false)} />
        </Grid.Col>
      ) : (
        <Grid.Col span={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            leftIcon={<IconPlus />}
            onClick={() => setShowNewOrderForm(true)}
          >
            Create new order
          </Button>
        </Grid.Col>
      )}
      {sortedOrders.map((order) => {
        if (order.status === "closed" || order.status === 1000) {
          return null;
        }

        return (
          <Grid.Col key={order._id} md={6} sm={12}>
            <Order
              order={order}
              isFake={order.isFake}
              setProfits={setProfits}
              currentPrice={currentPrice}
            />
          </Grid.Col>
        );
      })}
    </Grid>
  );
}
